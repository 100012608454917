<template>
  <div class="leave" @scroll="handleScroll">
    <div class="tab">
      <div class="tab-item" @click="onClick(0)">
        <span style="padding: 0.15rem;" :class="form.param.dealStatus == 0 ? 'active-item':''">处理中</span>
      </div>
      <div class="tab-item" @click="onClick(1)">
        <span style="padding: 0.15rem;" :class="form.param.dealStatus == 1 ? 'active-item':''">处理完成</span></div>
    </div>
    <div class="list">
      <div class="list-item" v-for="(item,index) in list" :key="index">
        <div class="row">
          <div class="label">内容：</div>
          <div class="content">{{item.content}}</div>
        </div>
        <div class="row">
          <div class="label">用户：</div>
          <div class="content">{{item.userName}}</div>
        </div>
        <div class="row">
          <div class="label">手机号：</div>
          <div class="content">{{item.mobile}}</div>
        </div>
        <div class="row">
          <div class="label">时间：</div>
          <div class="content">{{item.createAt | formatTime('{y}-{m}-{d} {h}:{i}:{s}')}}</div>
        </div>
        <div class="row" style="justify-content: flex-end">
          <div class="row-btn" @click="handleClick(item.id)" v-if="form.param.dealStatus != 1">处理</div>
        </div>
      </div>
    </div>
    <el-empty v-if="list.length == 0" description="暂无留言"></el-empty>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose">
      <span>是否处理留言？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleLeave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setRemBaseFontSize } from "@/utils/common";
import request from "@/utils/request";
export default {
  data() {
    return {
      threshold: 50,
      isLoading: false,
      form: {
        pageNum: 1,
        pageSize: 10,
        param: {
          dealStatus: "0",
          app: "",
        },
      },
      list: [],
      total: 0,
      dialogVisible: false
    };
  },
  created() {
    document.title = '留言'
    this.setRemBaseFontSize();
    // 监听窗口大小变化事件，以更新基准字体大小
    window.addEventListener("resize", this.setRemBaseFontSize);
    this.serviceCode = this.$route.query.serviceCode;
    this.actionCode = this.$route.query.actionCode;
    this.app = this.$route.query.app;
    this.getListInit()
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setRemBaseFontSize,
    handleClose() {
      this.dialogVisible = false
    },
    handleLeave() {
      const form = {
        id:this.id,
        app:this.app
      }
      request.post('/api/leaveMessage/complete',form).then(res => {
        this.$message.success('处理成功')
        this.dialogVisible = false
        this.getListInit()
      })
    },
    handleClick(id) {
      this.id = id
      this.dialogVisible = true
    },
    onClick(e) {
      this.form.param.dealStatus = e
      this.getListInit()
    },
    getListDown() {
      if (this.form.pageNum * this.form.pageSize >= this.total) {
        this.isLoading = false
        return;
      } else {
        this.form.pageNum = this.form.pageNum + 1;
      }
      this.form.param.app = this.app
      request
        .post("/api/leaveMessage/getPageInfo", this.form)
        .then((res) => {
          this.list = [...this.list, ...res.list];
          this.total = res.total;
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        });
    },
    getListInit() {
      this.form.pageNum = 1;
      this.form.param.app = this.app
      request
        .post("/api/leaveMessage/getPageInfo", this.form)
        .then((res) => {
          if (res.list.length != 0) {
            this.list = res.list;
            this.total = res.total;
          } else {
            this.list = res.list;
            this.total = res.total;
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        });
    },
    handleScroll() {
      const scrollContainer = document.querySelector(".leave");
      if (scrollContainer) {
        const scrollTop = scrollContainer.scrollTop;
        const scrollHeight = scrollContainer.scrollHeight;
        const clientHeight = scrollContainer.clientHeight;
        // console.log(scrollTop + clientHeight);
        // console.log(scrollTop);
        // console.log(clientHeight);
        // console.log(scrollHeight);
        if (
          scrollTop + clientHeight >= scrollHeight - this.threshold &&
          !this.isLoading
        ) {
          this.getListDown();
        }
      }
    },
    loadMore() {
      this.isLoading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.leave {
  height: 100vh;
  background-color: #f2f2f2;
  font-size: 0.16rem;
  overflow: scroll;
  .tab {
    display: flex;
    background-color: #fff;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
    .active-item {
      border-bottom: 1px solid #40aaff;
      color: #40aaff;
      padding: 0.15rem;
    }
  }
  .list-item {
    background-color: #fff;
    padding: 0.16rem;
    border-bottom: 1px solid #dcdfe6;
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 0.08rem;
      .label {
        width: 0.7rem;
      }
      &-btn {
        background-color: #40aaff;
        color: #fff;
        padding: 0.08rem 0.1rem;
        border-radius: 0.04rem;
      }
    }
  }
}
</style>
